import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useDebouncedValue } from '-/hooks';
import { ErrorMessage } from '../Errors';
import Text from '../Text/Text';

import type { ReactNode } from 'react';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  caption?: string;
  label?: string | ReactNode;
  showRequiredStar?: boolean;
  inputClassName?: string;
  inputWrapperClassName?: string;
  labelClassName?: string;
  showCharacterCount?: boolean;
  characterCountLabel?: string;
  inputPrefix?: string | ReactNode;
  rightAccessories?: ReactNode;
}

interface StyledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputWrapperClassName?: string;
  inputPrefix?: string | ReactNode;
  inputClassName?: string;
  rightAccessories?: ReactNode;
}

interface DebouncedInputProps extends InputProps {
  delay?: number;
}

export const DebouncedInput = React.forwardRef<
  HTMLInputElement,
  DebouncedInputProps
>(({ delay = 300, onChange, value, defaultValue, ...props }, ref) => {
  const [internalValue, setInternalValue] = useState<string>(
    (value as string) || (defaultValue as string) || ''
  );

  // Using the useDebouncedValue hook to debounce the internal value
  const debouncedValue = useDebouncedValue(internalValue, delay);

  // Store the previous debounced value to avoid calling onChange on the first render
  const previousDebouncedValue = useRef(debouncedValue);

  // Handle direct input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
  };

  useEffect(() => {
    // Prevent triggering onChange on initial render
    if (debouncedValue !== previousDebouncedValue.current) {
      previousDebouncedValue.current = debouncedValue;

      if (onChange) {
        const event = {
          target: {
            value: debouncedValue,
          },
        } as React.ChangeEvent<HTMLInputElement>;

        onChange(event);
      }
    }
  }, [debouncedValue, onChange]);

  return (
    <Input ref={ref} {...props} value={internalValue} onChange={handleChange} />
  );
});

DebouncedInput.displayName = 'DebouncedInput';

const StyledInput = forwardRef<HTMLInputElement, StyledInputProps>(
  (
    {
      inputPrefix,
      inputClassName,
      inputWrapperClassName,
      rightAccessories,
      ...props
    },
    ref
  ) => {
    const disabledClasses = props.disabled
      ? 'disabled:cursor-not-allowed disabled:bg-gray-100'
      : '';

    return (
      <div className={twMerge('flex', inputWrapperClassName)}>
        {inputPrefix && (
          <span
            className={twMerge(
              'prefix flex items-center text-base mt-1 bg-gray-200 border border-brown-900 border-r-0 px-3 pointer-events-none',
              inputClassName
            )}
          >
            {inputPrefix}
          </span>
        )}
        {rightAccessories ? (
          <div className="flex flex-grow mt-1 justify-between items-center border focus-within:border-green-900 focus-within:ring-green-900">
            <input
              ref={ref}
              className={twMerge(
                'form-input px-3 py-1.5 text-base w-full flex-grow border-none outline-none focus:outline-none focus:ring-0',
                disabledClasses,
                inputClassName
              )}
              {...props}
            />
            {rightAccessories}
          </div>
        ) : (
          <input
            ref={ref}
            className={twMerge(
              'form-input mt-1 px-3 py-1.5 text-base w-full border border-brown-900 focus:border-green-900 focus:ring-green-900',
              disabledClasses,
              inputClassName
            )}
            {...props}
          />
        )}
      </div>
    );
  }
);

StyledInput.displayName = 'StyledInput';

const InputWithError = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    error?: string;
    caption?: string;
    showCharacterCount?: boolean;
    characterCountLabel?: string;
  }
>(
  (
    {
      error,
      caption,
      maxLength,
      characterCountLabel,
      showCharacterCount = false,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<string>(
      (props.defaultValue as string) || (props.value as string) || ''
    );

    // Update input value on change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (maxLength && e.target.value.length > maxLength) {
        return; // Do nothing if maxLength is exceeded
      }

      setInputValue(e.target.value);
      if (props.onChange) props.onChange(e);
    };

    const currentLength = inputValue.length;
    const label = characterCountLabel ? `${characterCountLabel}: ` : '';
    const charactersLeft = maxLength
      ? `${label}${currentLength}/${maxLength}`
      : '';

    return (
      <>
        <StyledInput
          ref={ref}
          {...props}
          maxLength={maxLength}
          onChange={handleChange}
        />
        {error && <ErrorMessage className="mt-1.5" error={error} />}
        {!error && (caption || showCharacterCount) && (
          <Text size="xs" as="span" className="mt-1 text-gray-600">
            {showCharacterCount && maxLength && `${charactersLeft}`}
            {showCharacterCount && maxLength && caption && ' . '}
            {caption}
          </Text>
        )}
      </>
    );
  }
);

InputWithError.displayName = 'InputWithError';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      className,
      labelClassName,
      showRequiredStar = false,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={twMerge('flex content-start flex-col w-full', className)}
        data-testid="aslan-input"
      >
        {label ? (
          <label className="flex content-start w-full flex-col">
            <span
              className={twMerge(
                'text-sm',
                showRequiredStar ? 'after:content-["*"]' : '',
                labelClassName
              )}
            >
              {label}
            </span>
            {children}
            <InputWithError ref={ref} {...props} />
          </label>
        ) : (
          <InputWithError ref={ref} {...props} />
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
